.messageSender {
  display: flex;
  flex-direction: column;
  background-color: var(--color-background-light);
  border-radius: 15px;
  box-shadow: 0 5px 7px -7px var(--color-primary);
  width: 100%;
}

.messageSender__top {
  display: flex;
  border-bottom: 1px solid var(--color-background-dark);
  padding: 15px;
}
.messageSender__top > form {
  flex: 1;
  display: flex;
}

.messageSender__top > form > input {
  outline-width: 0;
  border: none;
  padding: 5px 20px;
  margin: 0 10px;
  border-radius: 999px;
  background-color: var(--color-background-dark);
  color: var(--color-text);
}

.messageSender__top > form > button {
  border: none;
  color: gray;
  width: 50px;
  background-color: var(--color-background-dark);
  cursor: pointer;
  border-radius: 8px;
}
.messageSender__top > form > button:hover {
  box-shadow: 0 5px 17px -7px var(--color-primary);
  color: var(--color-secondary);
}

.messageSender__input__summary {
  flex: 1;
}

.messageSender__input__summary:focus, .messageSender__input__url:focus {
  box-shadow: 0 5px 17px -7px var(--color-primary);
}

.messageSender__bottom {
  display: flex;
  justify-content: space-evenly;
}

.messageSender__option {
  padding: 20px;
  display: flex;
  align-items: center;
  color: var(--color-text);
  margin: 5px;
}

.messageSender__option > h3 {
    font-size: medium;
    margin-left: 10px;
    cursor: pointer;
}

.messageSender__option:hover {
    background-color: var(--color-background-dark);
    border-radius: 20px;
}