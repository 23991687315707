.slide {
  clear: both;
  width: 300px;
  height: 0px;
  overflow: hidden;
  text-align: left;
  transition: height 0.4s ease;
  position: absolute;
  top:80px;
  left: 40%;
  background-color: var(--color-background-light);
  box-shadow: 0 5px 7px -7px var(--color-primary);
}

.slide li {
  padding: 30px;
}

#touch {
  position: absolute;
  opacity: 0;
  height: 0px;
}

#touch:checked + .slide {
  height: 300px;
}

li {
  list-style-type: none;
  text-decoration: none;
  color: var(--color-text);
}
