.top__rankings {
  margin: 20px;
}

table {
  background-color: transparent;
  overflow: hidden;
  width: 300px;
}
.top__rankings__workers__header{
  border-top-left-radius: 5px;
}
table th,
table td,
table tr {
  padding: 0;
  border-width: 0;
  background-color: var(--color-background-light);
  border-radius: 5px;
  font-size: small;
  text-align: left;
  vertical-align: bottom;
  -webkit-box-shadow: 0 5px 5px -5px var(--color-secondary);
  -moz-box-shadow: 0 5px 5px -5px var(--color-secondary);
  box-shadow: 0 10px 10px -12px var(--color-secondary);
}
.top__rankings__managers > table th,
.top__rankings__managers > table td,
.top__rankings__managers > table tr {
  -webkit-box-shadow: 0 5px 5px -5px var(--color-primary);
  -moz-box-shadow: 0 5px 5px -5px var(--color-primary);
  box-shadow: 0 10px 10px -12px var(--color-primary);
}

table td,
table th {
  padding: 5px;
}

.top__rankings__workers__header, .top__rankings__managers__header {
  display: flex;
  align-items: center;
}

.top__rankings__workers__header > span, .top__rankings__managers__header > span {
  margin-left: 10px;
  font-size: small;
}

.top__rankings__managers{
    margin-top: 50px;
}

table th,
.top__rankings__name {
  text-transform: uppercase;
  background-color: transparent;
  color: var(--color-text);
}
table td {
  background-color: transparent;
  color:  var(--color-text);
}

table th {
  color: var(--color-secondary);
}

.top__rankings__managers > table th {
  color: var(--color-primary);
}

table tr {
  transition: all 0.8s ease-in-out;
}
table .slide-out {
  transform: translateX(110%);
}
.hidden__div{
    display: none;
}